<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    hide-details
    item-text="name"
    item-value="value"
    label="Search client"
  ></v-autocomplete>
</template>

<script>
  import AppService from '../services/app.service'

  export default {
    data: () => ({
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
      fetchTime: 0,
    }),
    computed: {
      currentClient () {
        return this.$store.getters['management/currentClient']
      },
    },
    watch: {
      model (val) {
        this.update(val)
      },
      currentClient (val) {
        this.update(val)
      },
      async search () {
        // Lazily load input items
        // Items have already been loaded
        if (Date.now() - this.fetchTime < 10 * 1000) return
        this.fetchTime = Date.now()

        await this.fetch()
      },
    },
    async beforeMount () {
      await this.fetch()
      this.model = this.$store.getters['management/currentClient']
      this.search = this.model
    },
    methods: {
      async fetch () {
        this.isLoading = true
        const clients = await AppService.management.refreshClients().finally(() => (this.isLoading = false))
        this.items = clients.map(c => ({ name: c, value: c }))
      },
      async update (val) {
        if (val) {
          this.$store.dispatch('management/setCurrentClient', val)
          await AppService.configuration.refreshCurrency()
          this.search = val
          this.$emit('input', val)
        }
      }
    }
  }
</script>
