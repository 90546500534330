<template>
  <v-flex class="d-flex page-container">
    <v-app-bar app fixed color="white" class="elevation-appbar">
      <v-app-bar-nav-icon class="d-md-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer class="d-md-none" />
      <div class="d-flex align-center justify-center pa-1" style="height: 75%; margin-right: 2.5%;">
        <img src="/static/logo.png" class="fill-height">
      </div>
      <v-tabs class="nav-tabs d-none d-md-flex" align-with-title>
        <v-tab router to="summary">
          <span>Summary</span>
        </v-tab>
      </v-tabs>
      <v-spacer />
      <div class="d-none d-md-flex mt-6">
        <v-select
          v-model="selectedToken"
          :items="currencies"
          persistent-hint
          return-object
          single-line
        ></v-select>
      </div>
      <div class="d-none d-md-flex">
        <v-menu offset-y :close-on-content-click="!isManageUser">
          <template v-slot:activator="{ on, attrs }">
            <v-btn plain v-bind="attrs" v-on="on">
              <div class="text-right">
                <strong class="d-block text-truncate" style="text-transform: none;">
                  {{ user['name'] }}
                </strong>
                <span v-if="isManageUser">{{ client }}</span>
              </div>
            </v-btn>
          </template>
          <v-list v-if="mountedReady">
            <v-list-item v-if="isManageUser">
              <v-list-item-title>
                <ManageClients />
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="isManageUser" class="mt-3 mb-5" />
            <v-list-item @click="signOut" class="text-center">
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed top temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <img src="/static/logo.png" style="height: 28px">
          </v-list-item-title>
          <v-list-item-subtitle>
            <strong class="d-block text-truncate" style="text-transform: none;">
              {{ user['name'] }}
            </strong>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="mountedReady && isManageUser">
        <v-list-item-title>
          <ManageClients />
        </v-list-item-title>
      </v-list-item>
      <div>
        <v-select
          v-model="selectedToken"
          :items="currencies"
          persistent-hint
          return-object
          single-line
        ></v-select>
      </div>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item-group v-model="group">
          <v-list-item router to="summary" @click="drawer = false">
            <v-list-item-title>Summary</v-list-item-title>
          </v-list-item>
          <v-list-item router to="execution" @click="drawer = false">
            <v-list-item-title>Execution</v-list-item-title>
          </v-list-item>
          <v-list-item router to="configuration" @click="drawer = false">
            <v-list-item-title>Configuration</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="secondary" @click="signOut" :loading="signOutLoading">
            Sign Out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main class="main-content">
      <PageLoader v-show="!(mountedReady && !appState.loading)" />
      <v-container v-if="mountedReady" v-show="!appState.loading" class="mt-3">
        <v-slide-y-transition mode="out-in">
          <router-view :key="$route.path"></router-view>
        </v-slide-y-transition>
      </v-container>
    </v-main>
    <v-footer padless class="elevation-2" color="white">
      <v-flex class="text-center">
        <div class="py-2">&copy; tradepoint.app 2021</div>
      </v-flex>
    </v-footer>
  </v-flex>
</template>

<script>
  // import appVersionChecking from './plugins/app-version'
  // import { schemas } from './configs/schemas'
  import appStore from './store/app'
  import UserService from './services/user.service.js'
  import AppService from './services/app.service.js'
  import ManageClients from './components/ManageClients'
  import PageLoader from './components/PageLoader'
  // import Highcharts from './libs/highcharts'

  export default {
    components: { ManageClients, PageLoader },
    data () {
      let navItems = [
        { type: 'subheader', title: 'Pages' }
      ]
      // const routes = this.$router.options.routes.find(r => r.path === '/').children.filter(r => {
      //   return r.meta.show !== false && UserService.hasPermission(r.meta.permission)
      // }) || []
      // routes.forEach(r => {
      //   navItems.push({
      //     ...r,
      //     type: 'item',
      //     icon: r.meta.icon,
      //     title: r.meta.title,
      //     to: r.name ? { name: r.name } : r.path
      //   })
      // })
      // /**
      //  * Collections
      //  */
      // navItems.push(
      //   { type: 'subheader', title: 'Databases' }
      // )
      // Object.keys(schemas).forEach(
      //   database => Object.keys(schemas[database]).forEach(
      //     collection => {
      //       if (UserService.hasPermission('collection', collection)) {
      //         navItems.push({
      //           type: 'item',
      //           icon: 'mdi-database',
      //           title: titleText(collection),
      //           to: { name: 'collection', params: { collection } }
      //         })
      //       }
      //     }
      //   )
      // )

      // navItems = navItems.concat([
      //   { type: 'divider' },
      //   // { icon: 'settings', text: 'Settings' },
      //   { type: 'item', icon: 'power_settings_new', title: 'Sign Out', to: '#', click: this.signOut }
      // ])

      return {
        user: this.$store.getters['user/user'],
        clipped: true,
        drawer: false,
        group: null,
        items: navItems,
        miniVariant: false,
        right: true,
        rightDrawer: false,
        // app data
        // schemas,
        // app-version
        appVersion: null,
        stopAppVersionChecking: null,
        signOutLoading: false,
        appState: appStore.state,
        mountedReady: false,
        selectedToken: this.$store.getters['currency/fromCurrency']
      }
    },
    watch: {
      group () {
        this.drawer = false
      },
      selectedToken (val) {
        const fromCurrency = this.$store.getters['currency/fromCurrency']
        if (fromCurrency.toUpperCase() !== val.toUpperCase()) {
          this.$store.dispatch('currency/setFromCurrency', val.toUpperCase())
        }
      },
      currencies (val) {
        const currentToken = val.find(c => c === this.$store.getters['currency/fromCurrency'])
        this.selectedToken = currentToken ? currentToken : val[0]
      }
    },
    computed: {
      isManageUser () {
        return UserService.hasPermission('management/user')
      },
      clients () {
        return this.$store.getters['management/clients']
      },
      client () {
        return this.$store.getters['management/currentClient']
      },
      currencies () {
        const currencies = this.$store.getters['currency/currencies']
        return currencies.map(c => c.from)
      }
    },
    methods: {
      async signOut () {
        this.signOutLoading = true
        try {
          UserService.logout()
        } catch (error) {
          console.error(error)
          this.signOutLoading = false
        }
        this.$router.replace({ name: 'signin' })
      }
    },
    beforeMount () {
      // this.stopAppVersionChecking = appVersionChecking(({ build }) => {
      //   if (this.appVersion === null) {
      //     this.appVersion = build
      //   }
      // })

      appStore.state.loading = true
    },
    async mounted () {
      try {
        const isManageUserPermission = UserService.hasPermission('management/user')
        if (isManageUserPermission) {
          await AppService.management.refreshClients()
        }
        await AppService.configuration.refreshCurrency()
      } catch (error) {
        console.error(error)
      } finally {
        this.appState.loading = false
        this.mountedReady = true
      }
    },
    beforeDestroy () {
      // this.stopAppVersionChecking && this.stopAppVersionChecking()
    }
  }
</script>

<style>
@import '~vuejs-noty/dist/vuejs-noty.css';
</style>

<style lang="scss" scoped>
  .page-container {
    flex-direction: column;
  }
  .main-content {
    background-color: #F8F6F0;
  }
</style>
